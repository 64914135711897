import React from 'react'

import Layout from '../components/Layout'
import { useSiteMetadata } from '../hooks'
import Hero from '../components/Hero'
import Gallery from '@browniebroke/gatsby-image-gallery'

const GalleryPage = ({ data }) => {
  const { title, description } = useSiteMetadata()

  // `images` is an array of objects with `thumb` and `full`
  const images = data.gallery.edges.map(({ node }) => node.childImageSharp)

  return (
    <Layout
      title={`Gallery | ${title}`}
      description={description}
    >
      <Hero className={'hero is-transparent is-medium'} isIndex={false} title={'Gallery'}/>

      <div className={'columns has-padding-top-30 has-padding-bottom-30 has-padding-20-mobile'}>
        <div className={'column is-offset-one-fifth is-three-fifths'}>
          <div className={'has-text-centered has-image-centered has-padding-bottom-50'}>
            <h4 className={'title'}>Photos from 2022</h4>
          </div>
          <div className={'columns'}>
            <div className={'column'}>
              <div className={'columns has-padding-bottom-50'}>
                <Gallery images={images}/>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query ImagesForGallery {
  gallery: allFile(filter: { sourceInstanceName: { eq: "gallery" } }
  sort: { fields: name }
  ) {
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
            originalName
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default GalleryPage

